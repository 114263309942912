import React, { Component } from "react";

import { Nav } from "react-bootstrap";
import TopBar from "react-bootstrap/Navbar";

class Navbar extends Component {
  render() {
    return (
      <TopBar bg='dark'>
        <Nav>
          <Nav.Link
            href='https://consensys.net/'
            target='_blank'
            rel='noopener noreferrer'
          >
            UBS Asset Subscription (DvP HTLC)
          </Nav.Link>
        </Nav>
      </TopBar>
    );
  }
}

export default Navbar;
