import React from "react";
import { Table } from "react-bootstrap";

const Subscription = (props) => {
  return (
    <Table>
      <tr>
        <th>Type</th>
        <th>Amount</th>
        <th>Requested By </th>
      </tr>
      <tr>
        <td>Acquisition</td>
        <td>{props.subscription.amount}</td>
        <td>{props.subscription.sender}</td>
      </tr>
    </Table>
  );
};

export default Subscription;
