import React, { useState } from "react";

import { Table } from "react-bootstrap";
import styled from "styled-components";

const Tab = styled.button`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const types = ["Assets", "Payments"];
const TabGroup = (props) => {
  const [active, setActive] = useState(types[0]);
  return (
    <>
      <ButtonGroup>
        {types.map((type) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>
      <div className='mt-4'>
        {active === "Assets" ? (
          <Table>
            <tr>
              <th>Token</th>
              <th>Spendable</th>
              <th>On Hold </th>
              <th>Total </th>
            </tr>
            <tr>
              <td>
                {props.holder.assets.tokenName}&nbsp;(
                {props.holder.assets.tokenSymbol})
              </td>
              <td>{props.holder.assets.balance}</td>
              <td>{props.holder.assets.onHoldBalance}</td>
              <td>{props.holder.assets.grossBalance}</td>
            </tr>
          </Table>
        ) : (
          <Table>
            <tr>
              <th>Token</th>
              <th>Spendable</th>
              <th>On Hold </th>
              <th>Total </th>
            </tr>
            <tr>
              <td>
                {props.holder.payments.tokenName} &nbsp;(
                {props.holder.payments.tokenSymbol})
              </td>
              <td>{props.holder.payments.balance}</td>
              <td>{props.holder.payments.onHoldBalance}</td>
              <td>{props.holder.payments.grossBalance}</td>
            </tr>
          </Table>
        )}
      </div>
    </>
  );
};

export default TabGroup;
