import "./App.css";

import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import React, { Component } from "react";

import ENV from "../env.json";
import ERC20 from "../abis/CodefiERC20.json";
import Navbar from "./Navbar";
import Subscription from "./Subscription";
import TabGroup from "./Balances";
import { newSecretHashPair } from "../utils/crypto";

const ethers = require("ethers");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentsNetworkId: ENV.paymentsNetworkId,
      assetsNetworkId: ENV.assetsNetworkId,
      investorAccount: ENV.networks[ENV.paymentsNetworkId].publicKey,
      issuerAccount: ENV.networks[ENV.assetsNetworkId].publicKey,
      selectedAccount: ENV.networks[ENV.paymentsNetworkId].publicKey,
      paymentsContract: ENV.networks[ENV.paymentsNetworkId].tokenContract,
      assetsContract: ENV.networks[ENV.assetsNetworkId].tokenContract,
      assets: {},
      payments: {},
      disableButton: false,
      inProgress: false,
      isOpen: false,
      buttonLabel: "Subscribe",
      role: 0,
      status: "",
      subscription: {},
      approval: {},
      holder: {
        assets: {
          tokenName: "",
          balance: 0,
          onHoldBalance: 0,
          grossBalance: 0,
        },
        payments: {
          tokenName: "",
          balance: 0,
          onHoldBalance: 0,
          grossBalance: 0,
        },
      },
    };
  }

  async componentWillMount() {
    await this.loadBlockchainData();
    await this.initTokenContract(0);
  }

  async loadBlockchainData() {
    const paymentsNetworkId = ENV.paymentsNetworkId;
    const assetsNetworkId = ENV.assetsNetworkId;

    const paymentsWriteInstance = await this.getWriteContractInstance(
      ERC20,
      paymentsNetworkId
    );
    this.setState({ paymentsWriteInstance });
    const paymentsReadInstance = await this.getReadContractInstance(
      ERC20,
      paymentsNetworkId
    );
    this.setState({ paymentsReadInstance });
    const assetsWriteInstance = await this.getWriteContractInstance(
      ERC20,
      assetsNetworkId
    );
    this.setState({ assetsWriteInstance });
    const assetsReadInstance = await this.getReadContractInstance(
      ERC20,
      assetsNetworkId
    );
    this.setState({ assetsReadInstance });

    const zeroGasPrice = ethers.utils
      .bigNumberify(0)
      .mul(ethers.utils.bigNumberify("1000000000"));
    this.setState({ zeroGasPrice });
    const gasPrice = ethers.utils
      .bigNumberify(20)
      .mul(ethers.utils.bigNumberify("1000000000"));
    this.setState({ gasPrice });
    const secretHash = newSecretHashPair();
    const subscriptionObj = {
      lockHash: secretHash.hash,
      secret: secretHash.secret,
    };
    this.setState({ subscription: subscriptionObj });
  }

  async getWallet(privateKey) {
    return new ethers.Wallet(privateKey);
  }

  async getProvider(networkId) {
    const network = networkId ? networkId : this.state.networkId;
    let providerJsonRpc = ENV.networks[network].jsonRpcProvider.url;
    if (ENV.networks[network].jsonRpcProvider.user) {
      providerJsonRpc = {
        url: ENV.networks[network].jsonRpcProvider.url,
        user: ENV.networks[network].jsonRpcProvider.user,
        password: ENV.networks[network].jsonRpcProvider.password,
      };
    }

    return new ethers.providers.JsonRpcProvider(providerJsonRpc);
  }

  async getWriteContractInstance(contractJson, networkId, privateKey) {
    const network = networkId ? networkId : this.state.networkId;
    const privKey = privateKey ? privateKey : ENV.networks[network].privateKey;
    if (privKey) {
      const ethersProvider = await this.getProvider(network);
      const contractAbi = contractJson.abi;
      let contract = ENV.networks[network].tokenContract;
      const contractObj = new ethers.Contract(
        contract,
        contractAbi,
        ethersProvider
      );
      const wallet = await this.getWallet(privKey);
      const fromSigner = wallet.connect(ethersProvider);
      const fromSignerContract = contractObj.connect(fromSigner);
      return fromSignerContract;
    } else {
      window.alert("Invalid address, there is no signing key.");
    }
  }

  async getReadContractInstance(contractJson, networkId) {
    const network = networkId ? networkId : this.state.networkId;
    const ethersProvider = await this.getProvider(network);
    const contractAbi = contractJson.abi;
    let contract = ENV.networks[network].tokenContract;
    return new ethers.Contract(contract, contractAbi, ethersProvider);
  }

  async startListenerNewHold(recipientAccount, contractInstance) {
    console.log("Starting listener for Token contract - NewHold");
    const filterTo = contractInstance.filters.NewHold(
      null,
      recipientAccount,
      null,
      null,
      null,
      null
    );
    contractInstance.on(
      filterTo,
      async (
        holdId,
        recipient,
        notary,
        amount,
        expirationDateTime,
        secretHash,
        event
      ) => {
        event.removeListener();
        if (this.state.issuerAccount === recipientAccount) {
          this.setState({
            subscription: { ...this.state.subscription, holdId },
          });
          const holderBalances = {
            assets: await this.getAllBalances(
              this.state.assetsReadInstance,
              this.state.investorAccount
            ),
            payments: await this.getAllBalances(
              this.state.paymentsReadInstance,
              this.state.investorAccount
            ),
          };
          this.setState({ holder: holderBalances });
          this.setState({ status: "Tokens subscription successful" });
        } else {
          this.setState({ approval: { ...this.state.approval, holdId } });
          const holderBalances = {
            assets: await this.getAllBalances(
              this.state.assetsReadInstance,
              this.state.issuerAccount
            ),
            payments: await this.getAllBalances(
              this.state.paymentsReadInstance,
              this.state.issuerAccount
            ),
          };
          this.setState({ holder: holderBalances });
          this.setState({ status: "Tokens approval successful" });
        }
        this.setState({ inProgress: false });
        console.log("Token contract new hold event received");
        console.log(
          holdId,
          recipient,
          notary,
          amount.toString(),
          expirationDateTime,
          secretHash
        );
      }
    );
  }

  async startPaymentsListenerTransfer(fromAccount, toAccount) {
    console.log(
      "Starting listener for Payments Token contract - Execute Hold (Transfer)"
    );
    const contractInstance = this.state.paymentsReadInstance;
    const filterTo = contractInstance.filters.Transfer(fromAccount, toAccount);

    contractInstance.on(filterTo, async (sender, recipient, amount, event) => {
      event.removeListener();
      console.log(
        "Token(Payments) contract execute hold(transfer) event received"
      );
      console.log(sender, recipient, amount.toString());
      await this.assetsExecuteHold();
    });
  }

  async startAssetsListenerTransfer(fromAccount, toAccount) {
    console.log(
      "Starting listener for Assets Token contract - Execute Hold (Transfer)"
    );
    const contractInstance = this.state.assetsReadInstance;
    const filterTo = contractInstance.filters.Transfer(fromAccount, toAccount);
    this.sleep(12000).then(() => {
      this.setState({ inProgress: false });
      this.setState({ status: "Tokens DvP successful" });
      this.setState({ subscription: {} });
      this.setState({ approval: {} });
    });
    contractInstance.on(filterTo, async (sender, recipient, amount, event) => {
      console.log(
        "Token(Assets) contract execute hold(transfer) event received"
      );
      console.log(sender, recipient, amount.toString());
      event.removeListener();
      this.setState({ inProgress: false });
      this.setState({ status: "Tokens DvP successful" });
      this.setState({ subscription: {} });
      this.setState({ approval: {} });
    });
  }

  async startPaymentsListenerRelease(hold) {
    console.log(
      "Starting listener for Payments Token contract - Release"
    );
    const contractInstance = this.state.paymentsReadInstance;
    const filterTo = contractInstance.filters.ReleaseHold(hold, null);

    contractInstance.on(filterTo, async (holdId, sender, event) => {
      event.removeListener();
      console.log(
        "Token(Payments) contract release hold event received"
      );
      console.log(holdId, sender);
      try {
        await this.assetsReleaseHold();
      } catch(err) {
        this.setState({ inProgress: false });
        this.setState({ status: err.message });
        this.setState({ subscription: {} });
        this.setState({ approval: {} });
      }
    });
  }

  async startAssetsListenerRelease(hold) {
    console.log(
      "Starting listener for Assets Token contract - Release"
    );
    const contractInstance = this.state.assetsReadInstance;
    const filterTo = contractInstance.filters.ReleaseHold(hold, null);

    contractInstance.on(filterTo, async (holdId, sender, event) => {
      event.removeListener();
      console.log(
        "Token(Assets) contract release hold event received"
      );
      console.log(holdId, sender);
      this.setState({ inProgress: false });
      this.setState({ status: "Tokens release hold successful" });
      this.setState({ subscription: {} });
      this.setState({ approval: {} });
    });
  }

  async initTokenContract(roleId) {
    const paymentsReadInstance = this.state.paymentsReadInstance;
    const assetsReadInstance = this.state.assetsReadInstance;
    let publicKey = "";
    if (0 === roleId) {
      publicKey = ENV.networks[this.state.paymentsNetworkId].publicKey;
    } else if (1 === roleId) {
      publicKey = ENV.networks[this.state.assetsNetworkId].publicKey;
    }

    const holderBalances = {
      assets: await this.getAllBalances(assetsReadInstance, publicKey),
      payments: await this.getAllBalances(paymentsReadInstance, publicKey),
    };
    this.setState({ holder: holderBalances });

    const assetsHolding = await this.getAllBalances(
      assetsReadInstance,
      ENV.networks[this.state.assetsNetworkId].publicKey
    );
    const paymentsHolding = await this.getAllBalances(
      paymentsReadInstance,
      ENV.networks[this.state.paymentsNetworkId].publicKey
    );
    this.setState({ assets: assetsHolding });
    this.setState({ payments: paymentsHolding });
  }

  async getAllBalances(contractInstance, publicKey) {
    const tokenSymbol = await contractInstance.symbol();
    const tokenName = await contractInstance.name();
    let balance = await contractInstance.balanceOf(publicKey);
    balance = this.truncateDecimals(balance);
    let onHoldBalance = await contractInstance.holdBalanceOf(publicKey);
    onHoldBalance = this.truncateDecimals(onHoldBalance);
    let grossBalance = await contractInstance.grossBalanceOf(publicKey);
    grossBalance = this.truncateDecimals(grossBalance);

    return {
      tokenName,
      tokenSymbol,
      balance,
      onHoldBalance,
      grossBalance,
    };
  }

  expandDecimals = (amount, decimals = 2) => {
    return ethers.utils.parseUnits(String(amount), decimals);
  };

  truncateDecimals = (amount, decimals = 2) => {
    return ethers.utils.formatUnits(amount, decimals);
  };

  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  handleRoleChange = async (e) => {
    this.setState({
      role: parseInt(e.target.value),
    });

    if (parseInt(e.target.value) === 0) {
      await this.initTokenContract(0);
      this.setState({ buttonLabel: "Subscribe" });
      this.setState({ selectedAccount: this.state.investorAccount });
      if (this.state.subscription.holdId) {
        this.amount = this.state.subscription.amount;
        this.setState({ disableButton: true });
      } else {
        this.setState({ disableButton: false });
      }
      if (Object.keys(this.state.subscription).length < 1) {
        const secretHash = newSecretHashPair();
        const subscriptionObj = {
          lockHash: secretHash.hash,
          secret: secretHash.secret,
        };
        this.setState({ subscription: subscriptionObj });
      }
    } else if (parseInt(e.target.value) === 1) {
      await this.initTokenContract(1);
      this.setState({ buttonLabel: "Approve" });
      this.setState({ selectedAccount: this.state.issuerAccount });
      if (this.state.subscription.holdId && !this.state.approval.holdId) {
        this.setState({ disableButton: false });
      } else {
        this.setState({ disableButton: true });
      }
    } else {
      this.setState({ buttonLabel: "DvP" });
      this.setState({ selectedAccount: "" });
      if (this.state.approval.holdId) {
        this.setState({ disableButton: false });
      } else {
        this.setState({ disableButton: true });
      }
    }
  };

  epochSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  epochSecondsToDisplay = (utcSeconds) => {
    const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    return new Date(date.setUTCSeconds(utcSeconds)).toLocaleString();
  };

  getExpiryDateTime = (minsToAdd) => {
    const now = new Date();
    const result = now.setMinutes(now.getMinutes() + parseInt(minsToAdd));
    return this.epochSeconds(new Date(result));
  };
  async userSubmit() {
    this.setState({ disableButton: true });
    const amount = this.amount.value;
    const recipient = this.state.issuerAccount;
    const expiryMins = this.expiryMins
      ? this.expiryMins.value
      : ENV.lockExpiryMins;
    const expiryDateTime = this.getExpiryDateTime(expiryMins);
    const subscriptionObj = {
      ...this.state.subscription,
      sender: this.state.investorAccount,
      recipient,
      notary: recipient,
      amount: amount,
      expirationDateTime: expiryDateTime,
    };
    this.setState({ subscription: subscriptionObj });
    this.setState({ inProgress: true });
    this.setState({
      status: "Subscription (payment tokens hold) is in progress.",
    });
    await this.startListenerNewHold(recipient, this.state.paymentsReadInstance);
    const contractObj = this.state.paymentsWriteInstance;
    const resp = await contractObj.hold(
      recipient,
      this.state.issuerAccount,
      this.expandDecimals(amount),
      expiryDateTime,
      subscriptionObj.lockHash,
      { gasPrice: this.state.zeroGasPrice }
    );
    console.log("hold transaction sent", resp.hash);
  }

  async approve() {
    this.setState({ disableButton: true });
    this.setState({ inProgress: true });
    this.setState({ status: "Approval (asset tokens hold) is in progress." });
    const subscriptionObj = this.state.subscription;
    console.log(JSON.stringify(subscriptionObj));

    const contractObj = this.state.assetsWriteInstance;
    const approvalObj = {
      sender: this.state.issuerAccount,
      recipient: subscriptionObj.sender,
      notary: subscriptionObj.sender,
      amount: subscriptionObj.amount,
      expirationDateTime: subscriptionObj.expirationDateTime,
      lockHash: subscriptionObj.lockHash,
      secret: subscriptionObj.secret,
    };
    try {
      await this.startListenerNewHold(
        subscriptionObj.sender,
        this.state.assetsReadInstance
      );
      this.setState({ approval: approvalObj });
      const resp = await contractObj.hold(
        subscriptionObj.sender,
        this.state.investorAccount,
        this.expandDecimals(subscriptionObj.amount),
        subscriptionObj.expirationDateTime,
        subscriptionObj.lockHash,
        { gasPrice: this.state.gasPrice }
      );
      console.log("hold transaction sent", resp);
    } catch(err) {
      this.setState({ inProgress: false });
      this.setState({ status: err.message });
    }
  }

  async dvp() {
    const nowDateTime = this.getExpiryDateTime(0);
    const expiryDateTime = this.state.approval.expirationDateTime;
    if (parseInt(nowDateTime) > parseInt(expiryDateTime)) {
      try {
        await this.paymentsReleaseHold();
      } catch(err) {
        this.setState({ inProgress: false });
        this.setState({ status: err.message });
        this.setState({ subscription: {} });
        this.setState({ approval: {} });
      }
    } else {
      try {
        await this.paymentsExecuteHold();
      } catch(err) {
        this.setState({ inProgress: false });
        this.setState({ status: err.message });
        this.setState({ subscription: {} });
        this.setState({ approval: {} });
      }
    }
  }

  async paymentsExecuteHold() {
    this.setState({ disableButton: true });
    this.setState({ inProgress: true });
    this.setState({ status: "Settlement (DvP) is in progress." });
    const subscriptionObj = this.state.subscription;
    console.log(JSON.stringify(subscriptionObj));
    const paymentsHoldId = subscriptionObj.holdId;
    const secret = subscriptionObj.secret;
    await this.startPaymentsListenerTransfer(
      this.state.investorAccount,
      this.state.issuerAccount
    );
    const paymentsContractObj = await this.getWriteContractInstance(
      ERC20,
      this.state.paymentsNetworkId,
      ENV.networks[this.state.assetsNetworkId].privateKey
    );
    const resp = await paymentsContractObj.executeHold(paymentsHoldId, secret, {
      gasPrice: this.state.zeroGasPrice,
    });
    console.log("Payments executeHold transaction sent", resp);
  }

  async paymentsReleaseHold() {
    this.setState({ disableButton: true });
    this.setState({ inProgress: true });
    this.setState({ status: "Release (Payments) hold is in progress." });
    const subscriptionObj = this.state.subscription;
    console.log(JSON.stringify(subscriptionObj));
    const paymentsHoldId = subscriptionObj.holdId;
    await this.startPaymentsListenerRelease(
      paymentsHoldId
    );
    const paymentsContractObj = this.state.paymentsWriteInstance;
    const resp = await paymentsContractObj.releaseHold(paymentsHoldId, {
      gasPrice: this.state.zeroGasPrice,
    });
    console.log("Payments releaseHold transaction sent", resp);
  }

  async assetsExecuteHold() {
    const approvalObj = this.state.approval;
    console.log(JSON.stringify(approvalObj));

    const assetsHoldId = approvalObj.holdId;
    const secret = approvalObj.secret;
    await this.startAssetsListenerTransfer(
      this.state.issuerAccount,
      this.state.investorAccount
    );
    const assetsContractObj = await this.getWriteContractInstance(
      ERC20,
      this.state.assetsNetworkId,
      ENV.networks[this.state.paymentsNetworkId].privateKey
    );
    const resp = await assetsContractObj.executeHold(assetsHoldId, secret, {
      gasPrice: this.state.gasPrice,
    });
    console.log("Assets executeHold transaction sent", resp);
  }

  async assetsReleaseHold() {
    this.setState({ status: "Release (Assets) hold is in progress." });
    const approvalObj = this.state.approval;
    console.log(JSON.stringify(approvalObj));
    const assetsHoldId = approvalObj.holdId;
    await this.startAssetsListenerRelease(
      assetsHoldId
    );
    const assetsContractObj = this.state.assetsWriteInstance;
    const resp = await assetsContractObj.releaseHold(assetsHoldId, {
      gasPrice: this.state.gasPrice,
    });
    console.log("Assets releaseHold transaction sent", resp);
  }

  setIsOpen = (open) => {
    this.setState({ isOpen: open });
  };

  togglePopup = () => {
    this.setIsOpen(!this.state.isOpen);
  };

  render() {
    return (
      <>
        <Navbar />
        <Container className='mt-5 mb-5'>
          <Form
            autoComplete='off'
            onSubmit={(event) => {
              event.preventDefault();
              if (this.state.role === 0) {
                this.userSubmit();
              } else if (this.state.role === 1) {
                this.approve();
              } else if (this.state.role === 2) {
                this.dvp();
              }
              this.togglePopup();
            }}
          >
            <Row>
              <Col md='6'>
                <Form.Group>
                  <Form.Label>Select role</Form.Label>
                  <Form.Control
                    as='select'
                    id='role'
                    name='role'
                    value={this.state.role}
                    size='lg'
                    onChange={this.handleRoleChange}
                  >
                    <option value='0'>Investor</option>
                    <option value='1'>Issuer</option>
                    <option value='2'>DvP</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {this.state.selectedAccount !== "" && (
              <Row>
                <Col md='6'>
                  <Alert variant='info'>
                    Account: {this.state.selectedAccount}
                  </Alert>
                </Col>
              </Row>
            )}

            {this.state.role === 1 && this.state.subscription.holdId && (
              <Row className='mt-3'>
                <Col md='6'>
                  <div className='info-container'>
                    <h3 className='h6 mb-3'>Hold Details</h3>
                    <Table className='mb-0 small'>
                      <tr>
                        <td className='pl-0 font-weight-bold'>
                          Hash Lock
                        </td>
                        <td>{this.state.subscription.lockHash}</td>
                      </tr>
                      <tr>
                        <td className='pl-0 font-weight-bold'>Expiry</td>
                        <td style={{ wordBreak: "break-all" }}>
                          {this.epochSecondsToDisplay(
                            this.state.subscription.expirationDateTime
                          )}
                        </td>
                      </tr>
                    </Table>
                  </div>
                  <Subscription
                    subscription={this.state.subscription}
                    approve={this.approve}
                  />
                </Col>
              </Row>
            )}

            {this.state.role === 0 && (
              <>
                <Row className='mt-2'>
                  <Col md='6'>
                    <div className='info-container'>
                      <h3 className='h6 mb-3'>Asset Details</h3>
                      <Table className='small mb-0'>
                        <tr>
                          <td className='pl-0 font-weight-bold'>Name</td>
                          <td>{this.state.assets.tokenName}</td>
                        </tr>
                        <tr>
                          <td className='pl-0  font-weight-bold'>Balance</td>
                          <td>{this.state.assets.balance}</td>
                        </tr>
                        <tr>
                          <td className='pl-0  font-weight-bold'>Symbol</td>
                          <td>{this.state.assets.tokenSymbol}</td>
                        </tr>
                      </Table>
                    </div>
                  </Col>
                </Row>
                {this.state.subscription.holdId ? (
                  <><Row className='mt-2'>
                    <Col md='6'>
                      <div className='info-container'>
                        <h3 className='h6 mb-3'>Hold Details</h3>
                        <Table className='mb-0 small'>
                          <tr>
                            <td className='pl-0 font-weight-bold'>
                              Hash Lock
                            </td>
                            <td>{this.state.subscription.lockHash}</td>
                          </tr>
                          <tr>
                            <td className='pl-0 font-weight-bold'>Expiry</td>
                            <td style={{ wordBreak: "break-all" }}>
                              {this.epochSecondsToDisplay(
                                this.state.subscription.expirationDateTime
                              )}
                            </td>
                          </tr>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                      <Col md='6'>
                        <div className='info-container'>
                          <h3 className='h6 mb-3'>Subscription Details</h3>
                          <Table className='mb-0 small'>
                            <tr>
                              <td className='pl-0 font-weight-bold'>
                                Amount of Tokens
                              </td>
                              <td>{this.state.subscription.amount}</td>
                            </tr>
                          </Table>
                        </div>
                      </Col>
                    </Row></>
                ) : (
                  <>
                    <Row className='mt-2'>
                      <Col md='6'>
                        <div className='info-container'>
                          <h3 className='h6 mb-3'>Hold Details</h3>
                          <Table className='mb-0 small'>
                            <tr>
                              <td className='pl-0 font-weight-bold'>
                                Hash Lock
                              </td>
                              <td>{this.state.subscription.lockHash}</td>
                            </tr>
                            <tr>
                              <td className='pl-0 font-weight-bold'>Expiry</td>
                              <td>
                              <Form.Group>
                            <Form.Control
                              id='expiryMins'
                              name='expiryMins'
                              type='number'
                              ref={(input) => {
                                this.expiryMins = input;
                              }}
                              className='form-control'
                              placeholder='mins'
                              size='lg'
                              required
                            />
                          </Form.Group>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md='4'>
                        <Form.Group>
                          <Form.Label>
                            Amount ({this.state.assets.tokenName})
                          </Form.Label>
                          <Form.Control
                            id='amount'
                            name='amount'
                            type='number'
                            ref={(input) => {
                              this.amount = input;
                            }}
                            className='form-control'
                            placeholder='Amount of Tokens'
                            size='lg'
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            {this.state.role === 2 && this.state.approval.holdId && (
              <Row className='mt-3'>
                <Col md='6'>
                  <div className='info-container'>
                    <h3 className='h6 mb-3'>Hold Details</h3>
                    <Table className='mb-0 small'>
                      <tr>
                        <td className='pl-0 font-weight-bold'>Payments Hold</td>
                        <td style={{ wordBreak: "break-all" }}>
                          {this.state.subscription.holdId}
                        </td>
                      </tr>
                      <tr>
                        <td className='pl-0 font-weight-bold'>Assets Hold</td>
                        <td style={{ wordBreak: "break-all" }}>
                          {this.state.approval.holdId}
                        </td>
                      </tr>
                      <tr className='form-group mr-sm-3'>
                        <td className='pl-0 font-weight-bold'>Secret</td>
                        <td style={{ wordBreak: "break-all" }}>
                          {this.state.approval.secret}
                        </td>
                      </tr>
                      <tr className='form-group mr-sm-3'>
                        <td className='pl-0 font-weight-bold'>Expiry</td>
                        <td>
                          {this.epochSecondsToDisplay(
                            this.state.approval.expirationDateTime
                          )}
                        </td>
                      </tr>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
            <div className='mt-4'>
              <Button
                variant='primary'
                type='submit'
                size='lg'
                disabled={this.state.disableButton}
              >
                {this.state.buttonLabel}
              </Button>
            </div>
          </Form>

          {this.state.role < 2 && (
            <div className='mt-5'>
              <TabGroup holder={this.state.holder} />
            </div>
          )}
          {this.state.isOpen && (
            <Modal centered show onHide={this.togglePopup}>
              <Modal.Header closeButton>
                <Modal.Title>Transaction Status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{this.state.status}</p>
                {this.state.inProgress ? (
                  <div className='image-container' row='true'>
                    <img src={require("../images/processing.gif")} alt='logo' />
                  </div>
                ) : null}
              </Modal.Body>
            </Modal>
          )}
        </Container>
      </>
    );
  }
}

export default App;
